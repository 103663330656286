import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './firebase';

import './style/App.css';
import './style/quick.css';
import App from './App';
import Login from './pages/Auth/Login';
import Home from './pages/Home';
import SignOut from './pages/Auth/SignOut';
import { ProvideAuth } from './hooks/useAuth';
import { ProvideFeed } from './hooks/useFeed';

render(
  <ProvideAuth>
    <ProvideFeed>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />}>
            <Route index element={<Home />} />
            {/* <Route path='login' element={<Login />} /> */}
            <Route path='sign-out' element={<SignOut />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ProvideFeed>
  </ProvideAuth>,
  document.getElementById('root')
);
