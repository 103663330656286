import { createContext, useContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  getFirestore,
  doc,
  addDoc,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';

const feedContext = createContext();

export function ProvideFeed({ children }) {
  const feed = useProvideFeed();
  return <feedContext.Provider value={feed}>{feed.loaded && children}</feedContext.Provider>;
}

export const useFeed = () => {
  return useContext(feedContext);
};

function useProvideFeed() {
  const [posts, setPosts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const submitPost = async post => {
    const db = getFirestore();
    const docRef = await addDoc(collection(db, 'posts'), post);
    return docRef.id;
  };

  const deletePost = async id => {
    return deleteDoc(doc(getFirestore(), 'posts', id));
  };

  const softDeletePost = async id => {
    const docRef = doc(getFirestore(), 'posts', id);
    await updateDoc(docRef, {
      softDelete: true,
    });
  };

  const hidePost = async (id, hidden) => {
    const docRef = doc(getFirestore(), 'posts', id);
    await updateDoc(docRef, {
      hidden: hidden,
    });
  };

  const updatePost = async post => {
    const { id, ...rest } = post;
    const docRef = doc(getFirestore(), 'posts', id);
    await updateDoc(docRef, {
      ...rest,
    });
  };

  const getDocsRequest = async () => {
    return getDocs(collection(getFirestore(), 'posts')).then(querySnapshot => {
      let posts = [];
      querySnapshot.forEach(doc => {
        console.log(doc.id, ' => ', doc.data());
        const post = { id: doc.id, ...doc.data() };
        posts.push(post);
      });

      console.log(posts);
      setPosts(posts);
    });
  };

  const getFeedRealtime = () => {
    return onSnapshot(collection(getFirestore(), 'posts'), querySnapshot => {
      let posts = [];
      querySnapshot.forEach(doc => {
        const post = { id: doc.id, ...doc.data() };
        console.log(post);
        posts.push(post);
      });

      console.log(posts);
      setPosts(posts);
      setLoaded(true);
    });
  };

  useEffect(() => {
    // const unsubscribe = getDocsRequest();
    const unsubscribe = getFeedRealtime();
    return () => unsubscribe();
  }, []);

  return {
    posts,
    submitPost,
    deletePost,
    softDeletePost,
    hidePost,
    updatePost,
    loaded
  };
}
