import { useEffect, useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import { useFeed } from '../../hooks/useFeed';
import TextareaAutosize from 'react-autosize-textarea';

const PostForm = ({ post }) => {
  const feed = useFeed();

  const [postText, setPostText] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleCreatePost = () => {
    const post = {
      timestamp: Date.now(),
      text: postText,
    };
    feed.submitPost(post);
    setPostText('');
    setShowModal(false);
  };

  // useEffect(() => {
  //   try {
  //     children.addEventListener('click', () => setShowModal(true), false);
  //   } catch (error) {}
  // }, [children]);

  return (
    <>
      {/* <div title='Write something' onClick={() => setShowModal(true)}>
        {children}
      </div> */}
      <Nav.Link
        className='text-gray-8'
        title='New Post'
        onClick={() => setShowModal(true)}>
        <i className='fad fa-lg fa-pen' />
      </Nav.Link>

      <Modal show={showModal} fullscreen onHide={() => setShowModal(false)}>
        <Modal.Header className='bg-gray-5 py-1' closeButton>
          <Modal.Title>
            <span style={{ fontSize: '1.8rem' }}>
              <i className='fad fa-typewriter'></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-gray'>
          <textarea
            style={{ height: '95%' }}
            placeholder='Write something...'
            className='post-textarea bg-gray'
            value={postText}
            onChange={e => setPostText(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className='bg-gray'>
          <button className='btn btn-dark px-5' onClick={handleCreatePost}>
            Post
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PostForm;
