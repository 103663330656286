import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDwyE0BfADvOCkgGw3jDKkXffLsCDFns64',
  authDomain: 'grandpatrick.firebaseapp.com',
  projectId: 'grandpatrick',
  storageBucket: 'grandpatrick.appspot.com',
  messagingSenderId: '519022460262',
  appId: '1:519022460262:web:30726bb8c2454cef615ca7',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();

export default app;
