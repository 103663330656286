import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useFeed } from '../../hooks/useFeed';
import { formatRelative } from 'date-fns';

const Feed = ({ controls }) => {
  const feed = useFeed();
  const auth = useAuth();

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    let posts = feed.posts.sort((a, b) => b.timestamp - a.timestamp);
    if (!auth.user) {
      posts = posts.filter(post => !post.hidden);
    }
    setPosts(posts);
  }, [auth.user, feed.posts]);

  return (
    <>
      <section id='feed' style={{ whiteSpace: 'pre-wrap' }}>
        {posts.map((post, idx) => {
          const displayDate = new Date(post.timestamp);
          return (
            <div
              key={idx}
              className='feed-post'
              style={{ marginBottom: '4rem', marginTop: '4rem' }}>
              <div className='row mb-3'>
                <div className='col-lg-3 offset-lg-1-'></div>

                <div className='col-lg-5 offset-lg-1'>
                  {post.hidden && <span className='text-light'>Hidden</span>}{' '}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-3 offset-lg-1-'>
                  <div className='text-end mb-lg-0 mb-4 d-none d-lg-block'>
                    <em>{formatRelative(displayDate, new Date())}</em>
                  </div>
                </div>
                <div className='col-lg-5 offset-lg-1'>
                  <div>{post.text}</div>
                  <div className='feed-post__bottom-row py-5 d-flex justify-content-between'>
                    <span className='me-3 text-gray-6 small d-lg-none'>
                      {/* <i className='fad fa-2x- fa-pen' /> */}
                      {formatRelative(displayDate, new Date())}
                    </span>

                    {auth.user && (
                      <div className='feed-post__controls--'>
                        <span
                          className='p-1'
                          style={{ cursor: 'pointer' }}
                          onClick={() => controls.handleEditPost(post)}>
                          <i className='fad fa-pen-to-square' />
                        </span>
                        {post.hidden ? (
                          <span
                            className='p-1'
                            style={{ cursor: 'pointer' }}
                            onClick={() => feed.hidePost(post.id, false)}>
                            <i className='fad fa-eye-slash' />
                          </span>
                        ) : (
                          <span
                            className='p-1'
                            style={{ cursor: 'pointer' }}
                            onClick={() => feed.hidePost(post.id, true)}>
                            <i className='fad fa-eye' />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default Feed;
