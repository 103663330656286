import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Container, Nav, Modal } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';
import PostForm from '../pages/Home/PostForm';
import { useState } from 'react';
import Login from '../pages/Auth/Login';

const NavBar = () => {
  const auth = useAuth();

  const [show, setShow] = useState(false);

  return (
    <>
      <Navbar collapseOnSelect bg='gray' variant='light' expand>
        <Container fluid className='px-lg-5-'>
          <LinkContainer to='/'>
            <Navbar.Brand className='text-gray-4-'>
              <div className='d-flex align-items-center'>
                <span
                  className='text-danger-'
                  style={{
                    marginRight: '.6rem',
                  }}>
                  <i className='fad fa-2x fa-transporter-2' />
                </span>
                <div style={{ lineHeight: 0.8 }}>
                  {/* <span className='text-light fw-bold' style={{letterSpacing:.5}}>Grand</span> */}
                  Grand
                  <br />
                  <em>Patrick</em>
                </div>
              </div>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto text-dark'>
              {!auth.user ? (
                // <LinkContainer to='/login'>
                //   <Nav.Link className='text-gray-8'>
                //     <i className='fad fa-lg fa-arrow-right-to-bracket' />
                //   </Nav.Link>
                // </LinkContainer>
                <Nav.Link className='text-gray-8' onClick={() => setShow(true)}>
                  <i className='fad fa-lg fa-arrow-right-to-bracket' />
                </Nav.Link>
              ) : (
                <>
                  <PostForm />
                  <Nav.Link
                    onClick={() => {
                      auth.signout();
                    }}>
                    <i className='fad fa-lg fa-arrow-right-from-bracket' />
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className='bg-gray-5 py-1' closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-gray'>
          <Login callback={() => setShow(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavBar;
