import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

const SignIn = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const SignOut = () => {
  return signOut(auth);
};

const AuthService = {
  SignIn,
  SignOut,
};

export default AuthService;
