import { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { Modal } from 'react-bootstrap';
import { useFeed } from '../../hooks/useFeed';
import Feed from './Feed';

const Home = () => {
  const feed = useFeed();

  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [editPost, setEditPost] = useState(null);

  const handleSubmitPost = () => {
    // if (!editPost) {
    //   const post = {
    //     timestamp: Date.now(),
    //     text,
    //   };
    //   feed.submitPost(post);
    // } else {
    const post = {
      ...editPost,
      id: editPost.id,
      lastUpdated: Date.now(),
      text,
    };
    console.log(post);
    feed.updatePost(post);
    // }
    setText('');
    setShow(false);
  };

  const handleEditPost = post => {
    setEditPost(post);
    setText(post.text);
    setShow(true);
  };

  return (
    <>
      <div className='container py-5'>
        {/* <PostForm /> */}
        <div className='my-4'></div>
        <Feed controls={{ handleEditPost }} />
      </div>

      <footer
        className='container-fluid bg-gray-3 text-gray-6 text-center'
        style={{ padding: '5rem 0' }}>
        <small>Content by Patrick Bradshaw</small>
      </footer>

      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header className='bg-gray-5 py-1' closeButton>
          <Modal.Title>
            <span style={{ fontSize: '1.8rem' }}>
              <i className='fad fa-typewriter'></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-gray'>
          <textarea
            style={{ height: '100%' }}
            placeholder='Write something...'
            className='post-textarea bg-gray'
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className='bg-gray'>
          <button className='btn btn-dark px-5' onClick={handleSubmitPost}>
            Post
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
