import { useEffect, useState } from 'react';
import AuthService from '../../services/AuthService';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Login = ({ callback }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();

  const handleSubmit = async e => {
    e.preventDefault();
    // TODO: useAuth
    await AuthService.SignIn(email, password);

    if (callback) callback();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-group mb-3'>
        <label htmlFor='email' className='form-label'>
          Email
        </label>
        <input
          type='email'
          name='email'
          className='form-control'
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='password' className='form-label'>
          Password
        </label>
        <input
          type='password'
          name='password'
          className='form-control'
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      <button className='btn btn-dark' type='submit'>
        Login
      </button>
    </form>
  );
};

export default Login;
