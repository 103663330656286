import { useEffect } from 'react';
import AuthService from '../../services/AuthService';

const SignOut = () => {
  useEffect(() => {
    AuthService.SignOut();
    try {
      AuthService.SignOut();
    } catch (error) {
      console.error('Sign out error? Weird.', error);
    }
  }, []);

  return (
    <div className='container-fluid py-5'>
      <h1>Signed out</h1>
    </div>
  );
};

export default SignOut;
